import React, { ReactElement, useEffect, useState } from 'react';
import { IBlock, IPageData, IPageProps } from '@Types/ContentfulTypes';
import { graphql } from 'gatsby';
import { usePageTheme, useApp } from '@Context/AppContext';
import ContentLayout from '@Src/components/sitestructure/ContentLayout';
import ContentfulModule from '@Src/components/ContentfulModule';
import SEO from '@Components/SEO';
import '@GraphQL/fragments';
import Signup from '@Src/components/Signup';
import LinkForm from '@Src/components/Signup/Form/link';

export interface Props extends IPageProps {
	data: {
		contentfulPageTypeSignup: IPageData;
	};
}

const SignupPage = ({ data, location, pageContext, transitionStatus }: Props): ReactElement => {
	const { title, seoSettings, content } = data.contentfulPageTypeSignup;
	const { setTheme: setPageTheme } = usePageTheme();
	const { mode, pageType, setPageType, setPageTitle } = useApp();
	const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);

	useEffect(() => {
		if (pageType === 'index' && transitionStatus == 'exiting') {
			setLocalTransStatus(transitionStatus + 'Index');
		} else {
			setLocalTransStatus(transitionStatus);
		}

		if (transitionStatus == 'entering' || transitionStatus == 'entered') {
			setPageTheme('light');
			setPageTitle(title);
			setPageType(pageContext.type);
		}
	}, [transitionStatus]);

	return (
		<ContentLayout transitionState={localTransStatus} fullWidth type={'signup'}>
			<SEO
				location={location.pathname}
				title={seoSettings?.title || title}
				description={seoSettings?.description}
				meta={seoSettings?.metaTags}
				image={seoSettings?.image}
			/>
			{content &&
				content.map(
					(module: IBlock, index: number): ReactElement => (
						<ContentfulModule key={module.id} type={module.type} data={module} />
					)
				)}
			<Signup>
				<LinkForm />
			</Signup>
		</ContentLayout>
	);
};

export default SignupPage;
export const query = graphql`
	query {
		contentfulPageTypeSignup {
			... on IPageNode {
				...PageFields
				content {
					... on IBlockNode {
						...AllBlocks
					}
				}
			}
		}
	}
`;
