import React, { ReactElement, useMemo, useCallback, useState, FormEventHandler, useEffect } from 'react';
import axios from 'axios';
import cx from 'classnames';
import Input from '@UI/Input';
import BaseButton from '@UI/BaseButton';
import { usePageTheme, useApp } from '@Context/AppContext';	
import { loadStripe } from '@stripe/stripe-js';
import Text from '@Src/components/Text';
import { createStripePaymentIntent } from '@Src/actions/aloaded';
import { Elements, ElementsConsumer, LinkAuthenticationElement, PaymentElement } from '@stripe/react-stripe-js';
import Loader from '@Src/components/ui/Loader';
import OrderSummary from '../OrderSummary';
import Checkbox from '@Src/components/ui/Checkbox';
import Select from '@Src/components/ui/Select';
import countries from './countries';
import * as styles from './index.module.scss';
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);

export interface FormData {
	email: string;
	firstname: string;
	lastname: string;
	company: string;
	selectDummy: string;
	acceptedTermsAndConditions?: boolean;
}

export interface ResponseData {
	data: {
		inlineMessage: string;
	};
}

interface Order {}

export interface Props {
	order?: {} | null;
	stripe?: any;
	elements?: any;
	onResponse?: (value: string) => void;
}

const LinkPaymentForm = ({ order, stripe, elements, onResponse }: Props): ReactElement => {
	const [postError, setPostError] = useState<boolean>(false);
	const [submit, setSubmit] = useState(false);
	const [email, setEmail] = useState<string>();
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [country, setCountry] = useState('');
	const [vatNumber, setVATNumber] = useState('');
	const [label, setLabel] = useState('');
	const [paymentError, setPaymentError] = useState(null);
	const { mode } = useApp();

	const [isEmailInvalid, setInvalidEmail] = useState(false);
	const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState(false);
	const [isPaymentReady, setPaymentReady] = useState(false);
	const [isLinkAuthenticationReady, setLinkAuthenticationReady] = useState(false);
	const [isPaymentFormReady, setPaymentFormReady] = useState(false);
	const [canSubmit, setCanSubmit] = useState(false);

	useEffect(() => {
		setCanSubmit(
			acceptedTermsAndConditions
			/*&& !isEmailInvalid
      && firstName?.length > 0
      && lastName?.length > 0*/
		);
	}, [acceptedTermsAndConditions, isEmailInvalid, firstName, lastName]);

	//add localStorage into hook to avoid ssr-errors
	useEffect(() => {
		const defaultValue = localStorage.getItem('email') || '';
		setEmail(defaultValue);
	}, []);

	let submitButtonCssClass = styles.submitButton;
	if (mode == "night") {
		submitButtonCssClass = styles.submitButtonNight;
	}

	React.useEffect(() => {
		console.log('is link authentication ready', isLinkAuthenticationReady && isPaymentReady);
		console.log('is payment ready', isPaymentReady);
		console.log('is payment< form ready', isLinkAuthenticationReady && isPaymentReady);
		setPaymentFormReady(isLinkAuthenticationReady && isPaymentReady);
	}, [isLinkAuthenticationReady, isPaymentFormReady]);
	const onTermsAndConditionsToggled = (event: MouseEvent) => {
		const checkBox = event.target as HTMLInputElement;
		setAcceptedTermsAndConditions(checkBox && checkBox.checked);
	};
	const onLinkAuthenticationReady = () => {
		setLinkAuthenticationReady(true);
	};
	const onPaymentElementReady = () => {
		setPaymentReady(true);
	};
	const onSubmit = async (event: any) => {
		event.preventDefault();
		setPostError(false);
		setSubmit(true);
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		const port = window.location.port == '80' ? '' : ':' + window.location.port;

		const result = await stripe.confirmPayment({
			//`Elements` instance that was used to create the Payment Element
			elements,
			confirmParams: {
				payment_method_data: {
					billing_details: {
						name: `${firstName} ${lastName}`,
            email
						//country: country // TODO Find out how to pass country with payment
					}
				},
				return_url: window.location.protocol + '//' + window.location.hostname + port + '/signup/processing'
			}
		});

		if (result.error) {
			setSubmit(false);
			setPostError(true);
			setPaymentError(result.error);
			// Show error to your customer (for example, payment< details incomplete)
			console.log(result.error.message);
		} else {
			// Your customer will be redirected to your `return_url`. For some payment
			// methods like iDEAL, your customer will be redirected to an intermediate
			// site first to authorize the payment, then redirected to the `return_url`.
		}
		return false;
	};
	console.log('countries', countries);
	let tosTableCssClasses = [styles["tosTable"]];
	if (mode === 'night') {
		tosTableCssClasses.push(styles["tosTableNight"]);
	}
	console.log(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)
	return (
		<div className={cx(styles.root, submit)}>
			<form onSubmit={onSubmit}>
				{isLinkAuthenticationReady && isPaymentReady ? (
					<div>
						<OrderSummary order={order} />
					</div>
				) : (
					<Loader />
				)}
       

				{/*isLinkAuthenticationReady && isPaymentReady ? (
					<>
						<div style={{ height: '43pt' }} />
						<div style={{ borderBottom: '1pt solid black' }}></div>
					</>
				) : null*/}
				<div style={{display: 'flex', alignItems: 'stretch', flexDirection: 'column', gap: 0}}>
        {isLinkAuthenticationReady && isPaymentFormReady &&
          <>
            <table cellSpacing={0} cellPadding={0} style={{width: '100%'}}>
              <tr>
                <td>
                  <Input
                    label="First Name"
                    id="firstName"
                    onUpdate={(value) => setFirstName(value)}
                    value={firstName}
                    required
                  />
                </td>
                <td>
                  <Input
                    label="Last Name"
                    id="firstName"
                    onUpdate={(value) => setFirstName(value)}
                    value={lastName}
                    required
                  />
                </td>
              </tr>
            </table>
            <div className={styles.field}>
              <Input
                label="label"
                id="label"
                onUpdate={(value) => setLabel(value)}
                value={label}
              />
            </div>
            </>
          }
					<LinkAuthenticationElement
            onChange={(e) => setEmail(e.value.email)}
						onReady={onLinkAuthenticationReady}
						// Optional prop for prefilling customer information
						options={{
							defaultValues: {
								email
							}
						}}
					/>
         
					{/*isLinkAuthenticationReady && isPaymentReady ? <div style={{ borderTop: '1pt solid black' }}></div> : null*/}
					<PaymentElement
						onReady={onPaymentElementReady}
						// Optional prop for prefilling customer information
						options={{
							defaultValues: {
								email
							}
						}}
					/>
				</div>

				{isLinkAuthenticationReady && isPaymentReady && false ? (
					<>
						<div className={styles.field} style={{padding: 10}}>
							<Input
								label="VAT Number (optional)"
								id="vatNumber"
								onUpdate={(value) => setVATNumber(value)}
								value={vatNumber}
								placeholder="SE123456789001"
							/>
						</div>
					</>
				) : null}
				
				{isLinkAuthenticationReady && isPaymentReady ? (
					<>
						{paymentError && <Text className={styles.error} htmlText={`Payment was declined`} />}
						<table className={cx(tosTableCssClasses)}>
							<tbody>
								<tr>
									<td>
										<label htmlFor="acceptTermsAndConditions" className={styles.checkBox}>
											<Checkbox theme={mode} onUpdate={(value) => setAcceptedTermsAndConditions(value)} />
										</label>
									</td>
									<td>
										<Text
											theme={mode}
											htmlText={`I accept the <a href="/terms-and-conditions" target="_blank">Terms and Conditions</a> for an Aloaded 12 month music distribution deal.`}
										/> 
									</td>
								</tr>
							</tbody>
						</table>
						<BaseButton
							className={submitButtonCssClass}
							isLoading={submit}
							isConfirm={true}
							disabled={!canSubmit}
							buttonType="submit"
							link={{
								url: '',
								id: 'submit',
								target: '_modal',
								label: submit ? 'Submitting your order... Please wait' : 'Pay & Register',
								primary: true
							}}
						/>

						<span className={cx(styles.postError)}>
							{' '}
							{postError && <>Something went wrong sending your request. Please try again later.</>}{' '}
						</span>
						<br />
						<br />
						<br />
						<br />
					</>
				) : null}
			</form>
		</div>
	);
};

export default function LinkForm({ onResponse, transitionStatus }: Props): ReactElement {
	const [clientSecret, setClientSecret] = useState();
	const [order, setOrder] = useState(null);
	const { mode, pageType, setPageType, setPageTitle } = useApp();

	const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);
  let query = new URLSearchParams()
  if (typeof window !== `undefined`) {
    query = new URLSearchParams(window.location.search)
  }

  const isDebug = query.get("isDebug999") == true;

	useEffect(() => {
		createStripePaymentIntent(isDebug).then((data) => {
			setClientSecret(data.data.clientSecret);
			localStorage.setItem('stripeClientSecret', data.data.clientSecret);
			setOrder(data.data.order);
			localStorage.setItem('order', JSON.stringify(order));
		});
	}, []);

	if (!clientSecret) {
		return (
			<div>
				<Loader />
			</div>
		);
	}
	const foreColor = mode == 'night' ? 'white' : 'black';
	const backColor = mode == 'night' ? 'black' : '#E7FD00';
	const grayColor = mode == 'night' ? '#ddd' : '#ddd';
	const appearance = {
		labels: 'floating',
		theme: mode == 'night' ? 'night' : 'none',
		rules: {
			base: {
				margin: 0
			},
			'.Label': {
				color: mode == 'night' ? 'white' : 'black',
				fontSize: '28pt'
			},
			'.Input': {
				colorTextPlaceholder: mode == 'night' ? 'gray' : '#333',
				boxShadow: 'none',
				borderBottom: '1px solid ' + foreColor,
				borderRight: '1px solid ' + foreColor,
				marginLeft: '0',
				fontFamily: 'Helvetica',
				fontSize: '1.8rem'
			},
			'.Input:focus': {
				boxShadow: 'none',
				fontFamily: 'Helvetica',
				fontSize: '1.8rem'
			}
		},
		variables: {
			spacingGridColumn: 0,
			colorTextPlaceholder: foreColor,
			colorPrimary: foreColor,
			colorBackground: backColor,
			grayColor,
			fontFamily: 'Helvetica',
			borderRadius: 0
		}
	};
	return (
		<Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
			<ElementsConsumer>
				{({ stripe, elements }) => ( 
					<LinkPaymentForm order={order} stripe={stripe} elements={elements} />							 
				)}
			</ElementsConsumer>
		</Elements>	
	);
}
